<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0">
          <div class="py-5 d-flex align-items-center justify-content-between">
            <h3 class="ana_h3 mb-0">New Link</h3>
            <div>
              <span class="cancel" @click="$router.go(-1)">
                <i class="ti-close"></i>
              </span>
            </div>
          </div>
          <form class="form" @submit.prevent="shortenURL">
            <div class="form-group">
              <label for="long_url" class="ana-label">Original URL</label>
              <div class="input-group mb-3 ana-input__group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2"
                    >http://</span
                  >
                </div>
                <input
                  type="text"
                  name="long_url"
                  class="form-control ana-input"
                  v-model.trim="$v.long_url.$model"
                  placeholder="tesla.com"
                  @change="generateShortId()"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for="prefix" class="ana-label"
                >URL Prefix
                <small
                  >(we will generate one for you if you leave this empty)</small
                ></label
              >
              <div class="input-group mb-3 ana-input__group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2"
                    >myana.com.ng/</span
                  >
                </div>
                <input
                  type="text"
                  name="prefix"
                  class="form-control ana-input"
                  v-model="url_code"
                  placeholder="tesla"
                  required
                />
              </div>
            </div>
            <div class="mt-3 mb-3" v-if="isLoading">
              <div class="linear-loader"></div>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="ana-btn ana-btn__fill"
                :disabled="isLoading || $v.$invalid"
              >
                Create link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import isURL from "@/middleware/isURL";
import { nanoid } from "nanoid";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  metaInfo: {
    title: "Create New Link - Ana app",
  },
  name: "Links",
  data() {
    return {
      link_title: "",
      long_url: "",
      url_code: "",
      isLoading: false,
    };
  },
  validations: {
    long_url: {
      required,
    },
  },
  methods: {
    shortenURL() {
      if (this.url_code.trim().length < 2) {
        this.$toast(`Ensure unique url is at least 2 characters long`, {
          timeout: 5000,
          type: "error",
        });
      } else {
        this.isLoading = true;
        let longURL = this.long_url;
        if (
          longURL.indexOf("http://") == 0 ||
          longURL.indexOf("https://") == 0
        ) {
          longURL = this.long_url;
        } else {
          longURL = `http://${this.long_url}`;
        }
        if (isURL.verify(longURL)) {
          const new_link = {
            link_title: this.link_title,
            long_url: longURL,
            url_code: this.url_code,
          };
          axios
            .post("short", new_link)
            .then((res) => {
              this.isLoading = false;
              this.$toast(`Your Link has been created successfully 🚀`, {
                timeout: 5000,
                type: "success",
              });
              this.$router.push("/dash/YourLinks");
            })
            .catch((err) => {
              this.isLoading = false;
              this.$toast(`${err.response.data.message}`, {
                timeout: 5000,
                type: "error",
              });
            });
        } else {
          this.isLoading = false;
          this.$toast(`Be sure to provide a valid URL`, {
            timeout: 6000,
            type: "error",
          });
        }
      }
    },
    generateShortId() {
      this.url_code = nanoid(8);
    },
  },
};
</script>
